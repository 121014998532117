import "react-app-polyfill/ie11"
import React from "react"
import ReactDOM from "react-dom"

import Chart from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"
// this is not possible to unregister globally, so we need to turn it off for each chart

import "./index.scss"
// react datepicker css
import "react-datepicker/dist/react-datepicker.css"
// react simplebar css
import "simplebar/dist/simplebar.min.css"
// tippy js css
import "tippy.js/dist/tippy.css"
import App from "app/App"
import { unregister } from "registerServiceWorker"

// modify default Chart.JS legend behaviour
import "extensions/chartLegend.ext"
// rounded rectangles
import "extensions/chartRoundedBars.ext"

// unregister globally ChartDataLabels plugin
Chart.plugins.unregister(ChartDataLabels)

ReactDOM.render(<App />, document.getElementById("root"))

unregister()

// Random line to force a re-build of the frontend
// eslint-disable-next-line
const x = 12
